<template>
  <ModalMainNew
    show-close-mobile
    modal-header-icon="fairness"
    :show-header-icon="activeView !== 'receipt'"
    :modal-header="modalHeader"
    @close="close()"
  >
    <section
      :class="{ 'pt-2' : activeView === 'receipt'}"
    >
      <template v-if="activeView === 'receipt'">
        <InstantGameReceipt/>
        <div
          class="pb-5 text-xs text-center text-slate-light font-semibold"
        >
          <button
            class="pb-2"
            type="button"
            @click.prevent.stop="showSeed"
          >
            {{ status === 'revealed' ? 'Verify now' : 'Rotate your seed pair in order to verify this bet' }}
          </button>
        </div>
      </template>
      <InstantGameSeed
        v-else-if="activeView === 'seed'"
        :active-sub-tab="subTab"
      />
    </section>
  </ModalMainNew>
</template>

<script setup>
const uiStore = useUiStore();
const { showFairnessModal, } = storeToRefs(uiStore);
const fairnessStore = useFairnessVerificationStore();
const { rawSeedData, } = storeToRefs(fairnessStore);
const { getInitialData, } = fairnessStore;

const activeView = ref('receipt');
const subTab = ref('seed');

const modalHeader = computed(() => activeView.value === 'receipt' ? 'Bet' : 'Fairness');
const status = computed(() => rawSeedData.value?.status ?? null);

function showSeed() {
  // TODO: use enums with typescript
  subTab.value = status.value === 'revealed' ? 'verify' : 'seed';
  activeView.value = 'seed';
}

function close() {
  showFairnessModal.value = false;
}
await getInitialData();
</script>

<template>
  <div class="pepe-container flex items-center justify-center relative w-full">
    <div class="pepe-fairness-result flex items-center justify-center px-2 absolute top-0" :class="gameResult.survived ? 'good' : 'bad'">
      <img
        :src="pepeUrl"
        alt="frog"
        class="mr-2.5"
      >
      <span class="text-xs">
        {{ gameResult.payout.toFixed(2).replace(/\.?0+$/, '') }} x
      </span>
    </div>
  </div>
</template>

<script setup>
const fairnessStore = useFairnessVerificationStore();
const { frogCrossingResult, } = fairnessStore;
const gameStore = useGamesStore();
const { assetUrl, } = storeToRefs(gameStore);
const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
  risk: {
    type: String,
    required: true,
  },
  stopIndex: {
    type: Number,
    required: true,
  },
});

const pepeUrl = computed(() => `${assetUrl.value}/frog-crossing/assets/history/pepe/${gameResult.value.survived ? 'good' : 'bad'}.svg`);
const gameResult = computed(() => frogCrossingResult(props.result, props.risk, props.stopIndex));
</script>

<style scoped lang="scss">
.pepe-fairness-result {
  height: 3rem;
  border-radius: 1.25rem;
  pointer-events: none;
  transform: scale(1.2) translateY(-50%);

  &::before {
    content: '';
    position: absolute;
    width: calc(100% - 0.5rem);
    height: calc(100% - 0.4rem);
    border-radius: 1rem;
    z-index: -1;
  }
}

.pepe-fairness-result.good {
  background-color: #3c883e;
  z-index: 1;
  will-change: transform;
  border: 2px solid yellow;
  box-shadow: #2b4440 0px 0px 0px 2px, #2b6537 0px 7px 0px 2px, #123232 0px 10px 0px 2px;
  text-shadow: #2b4440 1px 1px 3px;

  &::before {
    border: 2px solid #3c883e;
    background: radial-gradient(circle at top right, #2b6537 55%, #58bd5a 55%);
  }

}

.pepe-fairness-result.bad {
  background-color: rgb(177, 12, 23);
  z-index: 100;
  will-change: transform;
  border: 2px solid #610f0a;
  box-shadow: #c12e1c 0px 0px 0px 0px, #610f0a 0px 7px 0px 0px, #330404 0px 10px 0px 0px;
  text-shadow: #301103 1px 1px 3px;

  &::before {
    height: calc(100% - 0.7rem);
    border: 2px solid white;
    background: radial-gradient(circle at top right, #610f0a 55%, #871912 55%);
  }
}
</style>

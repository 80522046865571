<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <div
      :class="{ 'active': rootItem.isActive }"
      class="flex items-stretch drop-down"
    >
      <button
        class="flex items-center nav-button rounded-md w-full relative"
        :class="{ 'active': rootItem.isActive, }"
        size="2xs"
        @click.prevent.stop="rootItem.action()"
      >
        <SvgIcon
          :icon="rootItem.icon"
          class="text-[#CBCBCB] icon"
          height="20"
          width="20"
        />
        <div
          v-if="navState === 'full'"
          class="label"
          :class="{ 'bold': rootItem.bold }"
        >
          {{ rootItem.label }}
        </div>
        <NavigationToolTip :label="rootItem.label"/>
      </button>
      <button
        v-if="dropdownEnabled"
        class="flex-none flex items-center nav-button rounded-md relative !m-0"
        size="2xs"
        @click.prevent.stop="toggleDropDown"
      >
        <SvgIcon
          icon="arrow-down"
          height="20"
          width="20"
          class="transition"
          :class="{ 'rotate-180 text-white': !promotionsNavIsCollapsed }"
        />
      </button>
    </div>
    <div v-if="(!promotionsNavIsCollapsed) && dropdownEnabled" class="dropdown-content mt-1">
      <template
        v-for="item in menuItems"
        :key="item.icon"
      >
        <button
          v-if="!item.disabled"
          class="flex items-center nav-button nav-sub rounded-md w-full relative"
          :class="{ 'active': item.isActive }"
          size="2xs"
          @click.prevent.stop="item.action()"
        >
          <SvgIcon
            :icon="item.icon"
            class="text-[#CBCBCB] icon"
            height="20"
            width="20"
          />
          <div v-if="navState === 'full'" class="label">
            {{ item.label }}
          </div>
        </button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useNavigation } from '@/composables/useNavigation';
import { getConfig } from '@/utils/getConfig';

const uiStore = useUiStore();
const { navState, promotionsNavIsCollapsed, showWeeklyRaffleModal, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();
const route = useRoute();
const dropdownEnabled = computed(() => getConfig('ENV_NAME') !== 'Prod' && navState.value === 'full');

const rootItem = computed(() => {
  return {
    icon: 'tag',
    label: 'Promotions',
    bold: true,
    isActive: route.path.includes('promotions') && !route.path.includes('salon-rouge'),
    action: () => sportsBettingRedirect('/promotions'),
  };
});

const menuItems = computed(() => [
  {
    icon: 'ico-ticket',
    label: '$50k Weekly Raffle',
    isActive: route.path.includes('promotions'),
    action: () => (showWeeklyRaffleModal.value = true),
  },
]);

function toggleDropDown() {
  promotionsNavIsCollapsed.value = !promotionsNavIsCollapsed.value;
}
</script>

<style lang="scss" scoped>
.navigation-section {
  .nav-button:not(.nav-sub) {
    color: white;
    &.highlight {
      color: #FFC100;
    }
  }
}

.icon :deep(svg){
  height: auto;
}
</style>

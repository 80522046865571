export const useRewardStore = defineStore('rewardStore', () => {
  const initiated = ref(false);
  const pendingRewards = ref([]);
  const inventoryId = ref(null);
  const processingClaim = ref(false);
  const salonRougeRewardStatus = ref('pending');
  const vipTransferRewardInfo = ref([]);

  const websocket = useWebsocketStore();
  const { subscribe, unsubscribe, } = websocket;

  const userStore = useUserStore();
  const { getUserData, } = userStore;

  const nuxtApp = useNuxtApp();
  const { $api, $rollbar, } = nuxtApp;

  async function init() {
    subscribe('Reward');

    websocket.$onAction(({ name, args, }) => {
      if (name === 'handleMessage') {
        if (args[0].type === 'Reward:Added') { useMessageHandler({ name, args, }, onRewardAdded, 'Reward:Added'); }
        if (args[0].type === 'Reward:Updated') { useMessageHandler({ name, args, }, onRewardUpdated, 'Reward:Updated'); }
      }
    });

    // Reset state before fetching new data
    pendingRewards.value = [];
    salonRougeRewardStatus.value = 'inactive';
    inventoryId.value = null;

    await fetchPendingRewards();
    await getVipTransferInfo();
    initiated.value = true;
  }

  function onRewardAdded(reward) {
    if (reward.status === 'Pending') {
      pendingRewards.value.unshift(reward);

      if (reward.template.identifier === 'KYFREE') {
        vipTransferRewardInfo.value = [reward,];
      }
    }
  }

  async function onRewardUpdated(update) {
    const reward = pendingRewards.value.find(r => r.id === update.id);
    if (!reward) {
      return;
    }
    if (update.status !== 'Pending') {
      update.status !== 'Completed' && pendingRewards.value.splice(pendingRewards.value.indexOf(reward), 1);

      if (reward.template.identifier === 'KYFREE') {
        vipTransferRewardInfo.value = [];
      }

      // Waiting for claimed status to come back to update ui
      if (update.status === 'Claimed' || update.status === 'Completed') {
        await fetchPendingRewards();
        await getVipTransferInfo();
        processingClaim.value = false;
        unsubscribe('Reward');
      }
      pendingRewards.value.length === 0 ? salonRougeRewardStatus.value = 'inactive' : salonRougeRewardStatus.value = update.status;
      return;
    }
    Object.assign(reward, update);
  }

  function unsubscribeRewardsSocket() {
    unsubscribe('Reward');
  }

  async function fetchPendingRewards() {
    try {
      pendingRewards.value = await $api('/reward/recent',
        {
          method: 'GET',
          params: {
            templateIdentifier: 'WAGERTARGET',
            limit: 1,
          },
        }
      );
      updateRewardStatuses(pendingRewards.value[0]);
    } catch (err) {
      $rollbar.error('method failed', err);
      salonRougeRewardStatus.value = 'inactive';
      pendingRewards.value = [];
    }
  }

  function updateRewardStatuses(reward) {
    if (!reward?.status || reward.status === 'Expired' || reward.status === 'Cancelled' || reward.status === 'Failed') {
      pendingRewards.value = [];
      salonRougeRewardStatus.value = 'inactive';
      return;
    }

    salonRougeRewardStatus.value = reward.status;

    if (salonRougeRewardStatus.value === 'Completed' || salonRougeRewardStatus.value === 'Claimed') {
      inventoryId.value = reward?.inventoryItemId;
    }
  }

  async function claimBonusReward() {
    try {
      processingClaim.value = true;
      const response = await $api(`inventory/${inventoryId.value}/claim`, {
        method: 'POST',
      });

      await getUserData();
      await fetchPendingRewards();

      return response;
    } catch (err) {
      $rollbar.error('claimBonusReward error:::', err);
      throw err;
    } finally {
      processingClaim.value = false;
    }
  }

  async function getVipTransferInfo() {
    try {
      vipTransferRewardInfo.value = await $api('/reward/recent', {
        method: 'GET',
        params: {
          status: 'Pending',
          templateIdentifier: 'KYFREE',
          limit: 1,
        },
      });
    } catch (err) {
      console.log('getVipTransferReward error:::', err);
    }
  }

  const isVipTransferPending = computed(() => vipTransferRewardInfo.value.some(item => item.status.toLowerCase() === 'pending'));

  function reset() {
    initiated.value = false;
    pendingRewards.value = [];
    inventoryId.value = null;
    processingClaim.value = false;
    salonRougeRewardStatus.value = 'pending';
    vipTransferRewardInfo.value = [];
    unsubscribeRewardsSocket();
  }

  return {
    init,
    claimBonusReward,
    fetchPendingRewards,
    onRewardUpdated,
    onRewardAdded,
    unsubscribeRewardsSocket,
    pendingRewards,
    processingClaim,
    salonRougeRewardStatus,
    inventoryId,
    initiated,
    reset,
    isVipTransferPending,
    getVipTransferInfo,
  };
});

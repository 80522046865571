<template>
  <p class="text-5xl font-bold text-[lime]">{{ gameResult }}x</p>
</template>

<script setup>
const fairnessStore = useFairnessVerificationStore();
const { limboResult, } = fairnessStore;

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const gameResult = computed(() => limboResult(props.result));
</script>

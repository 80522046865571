<template>
  <div
    :class="[
      'bg-[#121212] h-[calc(100vh-70px)] fixed p-4 left-0 hidden lg:block w-full lg:w-[--nav-left-mini-width] z-30 lg:z-20 transition-transform overflow-auto scrollbar-hide nav-left',
      { '-translate-x-full' : ['hidden', 'full'].includes(navState) },
      { 'top-[57px] lg:top-[90px]' : hasRotator && isUserLogged },
      { 'top-[57px] lg:top-[70px]' : hasRotator && !isUserLogged },
      { 'top-[57px] lg:top-[70px] ' : !hasRotator }
    ]"
  >
    <nav v-if="isUserLogged">
      <transition name="bottom-pop">
        <NavigationSectionVipTransfer v-if="isUserLogged && isVipTransferPending"/>
      </transition>
      <Border v-if="isUserLogged"/>
      <div v-for="item in items" :key="item.icon" class="navigation-section w-full rounded-md collapsed">
        <button
          class="flex items-center nav-button rounded-md w-full relative p-1"
          :class="{ 'active': item.isActive }"
          size="2xs"
          @click.prevent.stop="item.action()"
        >
          <SvgIcon
            :icon="item.icon"
            :class="item.iconColor"
            class="text-[#CBCBCB] icon !w-[18px]"
            height="18"
            width="18"
          />
          <div v-if="navState === 'full'" class="label !ml-0 text-white">
            {{ item.label }}
          </div>
          <NavigationToolTip :label="item.label"/>
        </button>
        <Border/>
      </div>
      <SwitchCompetitionValue
        v-if="isUserLogged"
        id="expandedCurrencyToggle"
        class="my-2"
        small
        vertical
        hide-info-text
      />
      <UserFlagSwitch small/>
    </nav>
    <nav v-else class="flex flex-col h-full justify-between">
      <NavigationSectionCommunity/>
      <SwitchCompetitionValue
        id="expandedCurrencyToggle"
        class="my-2"
        small
        vertical
        hide-info-text
      />
      <UserFlagSwitch small/>
    </nav>
</div>
</template>

<script setup>
import { useNavigation } from '@/composables/useNavigation';

defineProps({
  hasRotator: {
    type: Boolean,
    default: false,
  },
});

const uiStore = useUiStore();
const {
  navState,
  casinoNavIsCollapsed,
  sportsNavIsCollapsed,
  userNavIsCollapsed,
} = storeToRefs(uiStore);

const authStore = useAuthStore();
const { isUserLogged, } = storeToRefs(authStore);

const userStore = useUserStore();
const { userData, } = storeToRefs(userStore);

const rewardStore = useRewardStore();
const { isVipTransferPending, } = storeToRefs(rewardStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const items = computed(() => [
  {
    icon: 'Present-solid',
    iconColor: 'inherit',
    label: 'Prizes',
    isActive: route.path === '/',
    action: () => {
      toggleDropdownState('other');
      sportsBettingRedirect('/');
    },
  },
  {
    icon: 'baccarat',
    iconColor: 'inherit',
    label: 'Instant Win',
    isActive: route.path.includes('/games/'),
    action: () => {
      toggleDropdownState('games');
      sportsBettingRedirect('/games/');
    },
  },
  {
    icon: 'salon-rouge',
    iconColor: 'red',
    label: 'Salon Rouge',
    isActive: route.path.includes('salon-rouge'),
    showDays: false,
    action: () => {
      toggleDropdownState('other');
      sportsBettingRedirect('/promotions/salon-rouge/');
    },
  },
  {
    icon: 'tag',
    iconColor: 'inherit',
    label: 'Promotions',
    bold: true,
    isActive: route.path.includes('promotions') && !route.path.includes('salon-rouge'),
    action: () => {
      toggleDropdownState('other');
      sportsBettingRedirect('/promotions');
    },
  },
  {
    icon: 'avatar',
    iconColor: 'inherit',
    label: 'Profile',
    isActive: route.path.includes(userData.value?.displayName) || !userNavIsCollapsed.value,
    action: () => {
      toggleDropdownState('profile');
      sportsBettingRedirect(`/${userData.value.displayName}/`);
    },
  },
  {
    icon: 'hub',
    iconColor: 'inherit',
    label: 'Bridge',
    isActive: route.path.includes('bridge'),
    action: () => {
      toggleDropdownState('other');
      sportsBettingRedirect('/bridge');
    },
  },
  {
    icon: 'support',
    iconColor: 'inherit',
    label: 'Support',
    isActive: route.path.includes('contact-us'),
    hide: false,
    action: () => {
      toggleDropdownState('other');
      sportsBettingRedirect('/contact-us');
    },
  },
]);

function toggleDropdownState(activeNav) {
  const navStates = {
    other: { casino: true, sports: true, user: true, community: true, },
    games: { casino: false, sports: true, user: true, community: true, },
    sports: { casino: true, sports: false, user: true, community: true, },
    profile: { casino: true, sports: true, user: false, community: true, },
  };

  casinoNavIsCollapsed.value = navStates[activeNav].casino;
  sportsNavIsCollapsed.value = navStates[activeNav].sports;
  userNavIsCollapsed.value = navStates[activeNav].user;
}
</script>

<style lang="scss" scoped>
.navigation-section {
  .nav-button {
    background: inherit;

    .label {
      color: white;
    }
    .icon.red {
      color: #E01515;
    }
  }
  .nav-button:hover {
    background:#1A1D26
  }
  .nav-button.active {
    background:#1A1D26
  }
}
</style>

<template>
  <p
    class="text-[9px] font-bold w-12 h-10 flex justify-center items-center text-[#000000cc]"
    :style="{ backgroundColor: noteColor }"
  >
    {{ gameResult }}x
  </p>
</template>

<script setup>
const fairnessStore = useFairnessVerificationStore();
const { plinkoResult, } = fairnessStore;

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
  risk: {
    type: String,
    required: true,
  },
  rows: {
    type: Number,
    required: true,
  },
});

const noteColor = ref('');
const gameResult = ref(null);

watchEffect(() => {
  if (props.result) {
    const { color, gameResult: result, } = plinkoResult(props.result, props.risk, props.rows);
    noteColor.value = color;
    gameResult.value = result;
  }
});
</script>

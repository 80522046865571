<template>
  <div v-click-outside="clickOutside" class="relative w-full">
    <div
      class="cursor-pointer rounded-md flex items-center py-3 px-4 transition-all w-full h-[52px]"
      :class="bg"
      @click.prevent="toggleDropdown"
    >
      <div class="overflow-hidden text-ellipsis">
        <DropdownListItemCurrency :is-small="isSmall" :is-fiat="isFiat" :currency="selectedCurrency"/>
      </div>

      <span
        class="icon-ico-down-pointer ml-auto"
        :class="{ 'rotate-180': isOpen }"
      />
    </div>

    <ul
      v-if="isOpen"
      class="py-2 max-h-60 min-w-[120px] overflow-y-auto absolute z-20 shadow-2xl rounded-xl bg-slate-700 scrollbar-hide w-full"
    >
      <li
        v-for="(option, index) in currencyOptions"
        :key="index"
        class="w-full px-5 py-2 hover:bg-slate-900 text-gray-400 hover:text-white transition-all duration-100 text-sm cursor-pointer"
        @click.prevent="handleSelectOption(option)"
      >
        <DropdownListItemCurrency :is-small="isSmall" :is-fiat="isFiat" :currency="option"/>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useMoonPay } from '~/composables/useMoonPay';
import { getCurrencyBadgeStyle } from '@/utils/getCurrencyBadgeStyle';
import { BlockchainNetwork, EVMBlockchainNetworks } from '@/types/BlockchainNetworks';

const props = defineProps({
  isFiat: {
    type: Boolean,
    default: false,
  },
  isSmall: {
    type: Boolean,
    default: false,
  },
  bg: {
    type: String,
    default: 'bg-slate-900',
  },
  update: {
    type: Boolean,
    default: false,
  },
  isMoonpayFilter: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'select',
]);

const authStore = useAuthStore();
const {
  wallet,
} = storeToRefs(authStore);

const uiStore = useUiStore();
const {
  walletModalFundsContext,
  txModalInfo,
} = storeToRefs(uiStore);

const bankingStore = useBankingStore();
const {
  balances,
} = storeToRefs(bankingStore);

const {
  fetchMoonpayFiatCurrencies,
  fetchMoonpayCryptoCurrencies,
} = useMoonPay();

const isOpen = ref(false);
const selectedCurrency = ref();
const fiatCurrencies = ref([]);
const moonpayAllowedCryptoCurrencies = ref([]);

const currencyOptions = computed(() => {
  if (props.isFiat) {
    return fiatCurrencies.value?.map((currency) => {
      return {
        code: currency.code.toUpperCase(),
        icon: currency.icon,
        type: 'fiat',
        maxBuyAmount: currency.maxBuyAmount,
        minBuyAmount: currency.minBuyAmount,
      };
    });
  } else {
    return balances.value
    .filter(balance => (balance.context === walletModalFundsContext.value) && !balance.virtual)
    .filter(balance =>
      walletModalFundsContext.value === 'Custodial'
      || (wallet.value?.network === BlockchainNetwork.Solana && Object.keys(balance.config.networks).includes(BlockchainNetwork.Solana))
      || (wallet.value?.network !== BlockchainNetwork.Solana && Object.keys(balance.config.networks).some(n => EVMBlockchainNetworks.includes(n)))
    )
    .filter(balance => selectedCurrency.value?.text !== balance?.label)
    .filter(balance => props.isMoonpayFilter ? moonpayAllowedCryptoCurrencies.value.find(currency => currency.code !== 'matic_polygon' ? currency.code.toUpperCase() === balance.name : balance.name === 'MATIC') : true)
    .map((balance) => {
      return {
        badge: {
          name: balance.name,
          classes: getCurrencyBadgeStyle(balance.name),
        },
        baseCode: balance.baseCode,
        code: balance.name,
        factor: balance.factor,
        icon: balance.name,
        minAmount: balance.config.deposit.minAmount,
        text: balance.label,
        type: 'crypto',
        decimals: balance.decimals,
      };
    });
  }
});

if (props.isFiat) {
  fiatCurrencies.value = await fetchMoonpayFiatCurrencies();
  const defaultFiatCurrency = fiatCurrencies.value.filter(currency => currency.code === 'usd').map((currency) => {
    return {
      code: currency.code.toUpperCase(),
      icon: currency.icon,
      type: 'fiat',
      maxBuyAmount: currency.maxBuyAmount,
      minBuyAmount: currency.minBuyAmount,
    };
  })[0];

  if (defaultFiatCurrency) {
    handleSelectOption(defaultFiatCurrency);
  }
} else {
  if (props.isMoonpayFilter) {
    moonpayAllowedCryptoCurrencies.value = await fetchMoonpayCryptoCurrencies();
  }
  const selectedOption = currencyOptions.value.filter(currency => currency.code === txModalInfo.value.currency);
  handleSelectOption(selectedOption[0] || currencyOptions.value[0]);
}

function clickOutside() {
  isOpen.value = false;
}

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function handleSelectOption(option) {
  selectedCurrency.value = option;
  emits('select', option);
  isOpen.value = false;
}

watch(
  () => props.update,
  () => {
    if (!props.isFiat) {
      const options = balances.value
      .filter(balance => (balance.context === walletModalFundsContext.value) && !balance.virtual)
      .filter(balance =>
      walletModalFundsContext.value === 'Custodial'
      || (wallet.value?.network === BlockchainNetwork.Solana && Object.keys(balance.config.networks).includes(BlockchainNetwork.Solana))
      || (wallet.value?.network !== BlockchainNetwork.Solana && Object.keys(balance.config.networks).some(n => EVMBlockchainNetworks.includes(n)))
      )
      .filter(balance => props.isMoonpayFilter ? moonpayAllowedCryptoCurrencies.value.find(currency => currency.code !== 'matic_polygon' ? currency.code.toUpperCase() === balance.name : balance.name === 'MATIC') : true)
      .map((balance) => {
        return {
          badge: {
            name: balance.name,
            classes: getCurrencyBadgeStyle(balance.name),
          },
          baseCode: balance.baseCode,
          code: balance.name,
          factor: balance.factor,
          icon: balance.name,
          minAmount: balance.config.deposit.minAmount,
          text: balance.label,
          type: 'crypto',
          decimals: balance.decimals,
        };
      });
      const selectedOption = options.filter(currency => currency.code === txModalInfo.value.currency);
      handleSelectOption(selectedOption[0] || currencyOptions.value[0]);
    }
  }
);
</script>

export function getCurrencyBadgeStyle(currency) {
  switch (currency) {
    case 'ETH':
      return 'bg-blue-500/20 text-blue-500';
    case 'BTC':
      return 'bg-orange-500/20 text-orange-500';
    case 'SOL':
      return 'bg-purple-500/20 text-pink-300';
    case 'USDT':
      return 'bg-green-500/20 text-green-500';
    case 'USDC':
      return 'bg-blue-300/20 text-blue-300';
    case 'EURC':
      return 'bg-blue-300/20 text-blue-300';
    case 'DAI':
      return 'bg-orange-300/20 text-orange-300';
    case 'LTC':
      return 'bg-gray-200/20 text-gray-200';
    case 'DOGE':
      return 'bg-yellow-500/20 text-yellow-500';
    case 'MATIC':
      return 'bg-purple-500/20 text-purple-500';
    case 'BNB':
      return 'bg-yellow-200/20 text-yellow-200';
    case 'TRX':
      return 'bg-red-500/20 text-red-500';
    case 'BCH':
      return 'bg-green-200/20 text-green-200';
    case 'XRP':
      return 'bg-gray-800 text-white';
    case 'EOS':
      return 'bg-blue-500/20 text-cyan';
    case 'ROCKY':
      return 'bg-orange-500/20 text-orange-300';
    case 'SHFL':
      return 'bg-purple-500/20 text-purple-400';
    case 'RLB':
      return 'bg-gray-800 text-yellow-200';
    case 'JCKPT':
      return 'bg-red-500/20 text-yellow-300';
    case 'WIF':
      return 'bg-cyan-500/20 text-cyan-400';
    case 'PP1K':
      return 'bg-green-600/20 text-green-600';
    case 'SHB1K':
      return 'bg-orange-500/20 text-orange-500';
    case 'BNK1K':
      return 'bg-orange-500/20 text-yellow-500';
    case 'PENGU':
      return 'bg-blue-500/20 text-blue-500';
    case 'TRUMP':
      return 'bg-orange-500/20 text-yellow-500';
    case 'DYDX':
      return 'bg-purple-500/20 text-blue-500';
    case 'APE':
      return 'bg-blue-600 text-white';
    default:
      return 'bg-slate-500/20 text-slate-200';
  }
}

import { useStorage } from '@vueuse/core';
import { useUserStore } from './user';
import { BlockchainNetwork, EVMBlockchainNetworks } from '@/types/BlockchainNetworks';

export const useBankingStore = defineStore({
  id: 'banking-store',
  state: () => {
    return {
      paymentMethod: null,
      allBalances: null,
      showDepositCompleted: false,
      initiated: false,
      balanceUpdateEnabled: true,
      balanceUpdateDuration: 0,
      balanceBuffer: null,
      activeCurrency: useStorage('activeCurrency', 'ETH'),
      activeContext: useStorage('activeContext', 'Default'), // Default, Custodial
      activeCurrencyOverride: null,
      activeContextOverride: null,
      balanceOverride: null,
      balanceLocked: false,
      excludeTurnableBalances: false,
      swapsPending: [],
    };
  },
  getters: {
    currentCurrency() {
      return this.activeCurrencyOverride ?? this.activeCurrency;
    },
    currentContext() {
      return this.activeContextOverride ?? this.activeContext;
    },
    balances() {
      if (!this.allBalances) {
        return [];
      }

      const balances = [];

      const breakdownOrder = ['deposit', 'depositCustodial', 'withdrawable', 'custodial', 'playable',];

      for (const currency of Object.keys(this.allBalances)) {
        const currBalance = this.allBalances[currency];
        const accounts = currBalance?.accounts;

        for (const accountKey of Object.keys(accounts)) {
          if (['playable', 'deposit', 'depositCustodial',].includes(accountKey)) {
            continue;
          }

          const playable = accounts.playable || 0;
          const balance = accounts[accountKey] || 0;
          const depositKey = accountKey === 'withdrawable' ? 'deposit' : 'depositCustodial';
          const deposit = accounts[depositKey] || 0;

          let value = +balance;

          if (!this.excludeTurnableBalances) {
            value += +playable + +deposit;
          }

          const exclusions = accountKey === 'withdrawable' ? ['depositCustodial', 'custodial',] : ['deposit', 'withdrawable',];
          balances.push({
            name: currency,
            decimals: currBalance?.decimals,
            label: currBalance?.name,
            value,
            virtual: currBalance?.virtual,
            factor: currBalance?.factor,
            baseCode: currBalance?.baseCode,
            context: accountKey === 'custodial' ? 'Custodial' : 'Default',
            config: currBalance?.config,
            breakdown: Object.keys(accounts).sort((a, b) => breakdownOrder.indexOf(a) - breakdownOrder.indexOf(b)).filter(account => !exclusions.includes(account)).map((account) => {
              return {
                name: account,
                value: accounts[account],
              };
            }),
          });
        }
      }

      return balances;
    },
    diamondBalance(state) {
      return state.allBalances !== null ? state.allBalances.diamonds?.ETH : null;
    },
  },
  actions: {
    async bankingInit() {
      this.initiated = true;
      const websocketStore = useWebsocketStore();
      websocketStore.$onAction(({ name, args, }) => {
        if (name === 'handleMessage') {
          if (args[0].type === 'Deposit:Pending') {
            useMessageHandler({ name, args, }, this.depositPending, 'Deposit:Pending');
          }
          if (args[0].type === 'Deposit:Complete') {
            useMessageHandler({ name, args, }, this.depositCompleted, 'Deposit:Complete');
          }
          if (args[0].type === 'Wallet:BalanceUpdated') {
            useMessageHandler({ name, args, }, this.balanceUpdate, 'Wallet:BalanceUpdated');
          }
          if (args[0].type === 'Withdrawal:StatusChange') {
            useMessageHandler({ name, args, }, this.withdrawalUpdate, 'Withdrawal:StatusChange');
          }
        }
      });

      await this.loadWallet();
    },
    /**
     * NB: Not currently used
     */
    async loadPaymentMethods() {
      try {
        const res = await useNuxtApp().$api('/payment/method/active', { method: 'GET', });
        this.paymentMethod = res?.data;
      } catch (err) {
        this.paymentMethod = null;
      }
    },
    async loadWallet() {
      this.balanceUpdateEnabled = true;
      try {
        const res = await useNuxtApp().$api('/banking/wallet/balance', { method: 'GET', });
        this.allBalances = Object.keys(res).length ? res : null;
      } catch (err) {
        this.allBalances = null;
      }
    },
    depositPending(payload) {
      if (!payload.providerRef) { return; }

      const index = this.swapsPending.findIndex(item => item?.txHash === payload.providerRef);

      // If the payment is already in the queue, ignore.
      if (index > -1) { return; }

      const uiStore = useUiStore();
      uiStore.showTxToastNotification('Pending', 'Deposit', payload.fundsContext);
      uiStore.showDepositAccountModal = false;

      this.swapsPending.unshift({ txHash: payload.providerRef, currencyCode: payload.currencyCode, direction: 'in', fundsContext: payload.fundsContext, });
    },
    depositCompleted(payload) {
      const uiStore = useUiStore();
      this.showDepositCompleted = true;
      uiStore.showTxToastNotification('Complete', 'Deposit', payload.fundsContext);

      // Find and remove item from swapsPending array using payload.providerRef
      const index = this.swapsPending.findIndex(item => item?.txHash === payload?.providerRef);
      if (index > -1) {
        this.swapsPending.splice(index, 1);
      }
    },
    balanceUpdate(payload) {
      if (!payload) { return; }

      if (!this.allBalances) {
        this.allBalances = {};
      }

      // Buffer update only when new balance is higher than the existing
      if (!this.balanceUpdateEnabled) {
        let buffered = false;
        payload.forEach((walletNode) => {
          const accountBalance = Number(this.allBalances[walletNode.currencyCode]?.accounts[_CamelCase(walletNode.account)] || 0);
          if (walletNode.account && (accountBalance < walletNode.balance)) {
            buffered = true;
          }
        });
        if (buffered) {
          this.balanceBuffer = payload;
          return;
        }
      }

      payload.forEach((walletNode) => {
        if (walletNode.account) {
          this.allBalances[walletNode.currencyCode] = this.allBalances[walletNode.currencyCode] || {};
          this.allBalances[walletNode.currencyCode].accounts[_CamelCase(walletNode.account)] = walletNode.balance;
        }
      });
    },
    enableBalanceUpdate(enable) {
      this.balanceUpdateEnabled = enable;
    },
    async getWithdrawals(page) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/withdrawal', {
          method: 'GET',
          params: {
            page,
            pageSize: 20,
          },
        });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    // TODO: Make this less specific
    async getWithdrawalPaymentMethods(types, providers) {
      try {
        const data = await useNuxtApp().$api('/payment/method', {
          method: 'GET',
          params: {
            types,
            providers,
            withdrawn: true,
          },
        });
        return data;
      } catch (err) {
        return [];
      }
    },
    async requestWithdrawal(currencyCode, amount, paymentMethod, fundsContext, network, tag) {
      try {
        const data = await useNuxtApp().$api(`/withdrawal/${currencyCode}`, {
          method: 'POST',
          body: {
            amount,
            paymentMethod,
            fundsContext,
            providerNetwork: network,
            tag,
          },
        }, 0);
        return data;
      } catch (err) {
        throw new Error(err.data?.message || 'An unexpected error occurred.');
      }
    },
    async resendWithdrawEmail(id) {
      const nuxtApp = useNuxtApp();
      try {
        await nuxtApp.$api(`/withdrawal/${id}/confirmation/resend`, {
          method: 'POST',
        });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async cancelWithdrawal(id) {
      const nuxtApp = useNuxtApp();
      try {
        await nuxtApp.$api(`/withdrawal/${id}`, { method: 'DELETE', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async cancelWithdrawalByToken(token) {
      const nuxtApp = useNuxtApp();
      try {
        await nuxtApp.$api(`/withdrawal/cancel/${token}`, { method: 'DELETE', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async confirmWithdrawalByToken(token) {
      const nuxtApp = useNuxtApp();
      try {
        await nuxtApp.$api(`/withdrawal/confirm/${token}`, { method: 'PATCH', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        throw err;
      }
    },
    async withdrawalAvailability(currencyCode, amount, fundsContext) {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api(`/withdrawal/${currencyCode}/available`, { method: 'POST', body: { amount: amount?.toString(), fundsContext, }, });
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        return err.data;
      }
    },
    withdrawalUpdate(payload) {
      const uiStore = useUiStore();

      if (payload.status === 'Confirmed') {
        uiStore.showTxToastNotification('Confirmed', 'Withdrawal', payload.fundsContext);
      } else if (payload.status === 'Approved') {
        uiStore.showTxToastNotification('Pending', 'Withdrawal', payload.fundsContext);
        this.swapsPending.unshift({ withdrawalId: payload.withdrawalId, currencyCode: payload.currencyCode, direction: 'out', fundsContext: payload.fundsContext, });
      } else if (['Cancelled', 'Declined', 'Error',].includes(payload.status)) {
        uiStore.showTxToastNotification('Error', 'Withdrawal', payload.fundsContext);
      } else if (payload.status === 'Complete') {
        // Find and remove item from swapsPending array using payload.id
        const index = this.swapsPending.findIndex(item => item?.withdrawalId === payload?.withdrawalId);
        if (index > -1) {
          this.swapsPending.splice(index, 1);
        }
        uiStore.showTxToastNotification('Complete', 'Withdrawal', payload.fundsContext);
      }
    },
    balanceAvailable(balance) {
      const userStore = useUserStore();
      const userData = userStore.userData;
      const hasEthereumAddress = !!userData?.aliases?.EthereumAddress;
      const hasSolanaAddress = !!userData?.aliases?.SolanaAddress;
      const hasAddress = hasEthereumAddress || hasSolanaAddress;
    
      if (balance.context === 'Custodial') {
        return true;
      }
    
      if ((!hasAddress && Object.keys(balance.config.networks).some(n => EVMBlockchainNetworks.includes(n)))) {
        return true;
      }

      if (hasSolanaAddress && Object.keys(balance.config.networks).includes(BlockchainNetwork.Solana)) {
        return true;
      }
    
      if (hasEthereumAddress && Object.keys(balance.config.networks).some(n => EVMBlockchainNetworks.includes(n))) {
        return true;
      }
    
      return false;
    }
  },
});

<template>
  <div class="section-no-wallet flex flex-col gap-4 relative">
    <DropdownCurrency :update="updateCurrency" @select="handleCurrencyChange"/>
    <div class="content">
      <div v-if="networkOptions.length > 1 && activeNetworkOption" class="dropdown mb-4 -mt-1">
        <div class="text-3xs font-bold mb-2 text-white ml-[1px]">Choose Network</div>
        <DropdownMain
          :options="networkOptions"
          :active-option="activeNetworkOption"
          theme="input-normal"
          full
          is-tx-modal
          @select="handleUpdateNetworkOption"
        >
          <div class="flex items-center">
            <SvgIcon
              :icon="activeNetworkOption.icon"
              width="24"
              height="24"
              class="my-auto"
            />
            <span class="mx-2">
              {{ activeNetworkOption.text }}
            </span>
          </div>
        </DropdownMain>
      </div>
      <div class="toggle mb-4">
        <div class="toggle relative cursor-pointer" @click.prevent="handleToggleTransferDirection(true)">
          <div
            v-if="!isToggleBlocked"
            class="absolute z-10 w-[40px] h-[40px] rounded-full bg-blue lg:hover:bg-blue-400 right-4 flex items-center justify-center top-14 transition-all duration-300 animate-slide-down-fade-in01s"
            :class="{ 'rotate-180': arrowsRotated }"
          >
            <span class="icon-ico-sort-arrows text-lg"/>
          </div>
          <transition-group name="list-position">
            <WalletToggleButton
              v-for="(button, index) in buttons"
              v-bind="button"
              :key="button.owner"
              :display-index="index"
              :toggle-blocked="isToggleBlocked"
              :currency="button.currency"
              :currency-factor="selectedCurrency?.factor"
            />
          </transition-group>
        </div>
        <div v-if="isToggleLoading" class="flex justify-center">
          <Spinner :has-logo="false" size="xs"/>
        </div>
        <div
          v-else-if="tab === walletModalModeTypes[1]"
          class="text-slate-100 text-xs lg:text-sm text-center my-5"
        >
          <span class="text-pink-400 uppercase tracking-wider">Zero friction guarantee</span>
          <p v-if="!!withdrawalAvailabilityDetails?.limit">
            {{ withdrawAmountPerDayMessage }}
          </p>
        </div>
        <div v-else class="text-slate-100 text-xs lg:text-sm text-center mt-5">
          <p class="text-pink-400 font-bold uppercase tracking-wider">Winning in Web3</p>
          <p class="text-slate-100 tracking-wider">Web3 deposits are instant.</p>
          <p class="text-slate-100 tracking-wider">You'll have your funds in a few seconds.</p>
        </div>
      </div>

      <VForm
        v-slot="{ values }"
        ref="SwapForm"
        :validation-schema="validationSchema"
        class="relative transition-all"
      >
        <div class="relative" :class="{ 'opacity-40': formDisabled }">
          <InputValidated
            name="transferAmount"
            type="number"
            step="0.01"
            placeholder="0"
            :max-decimal-places="selectedCurrency?.decimals || 8"
            hide-validation-tick
            :value="transferAmount"
            :disabled="formDisabled"
            :currency="selectedCurrency?.code"
            :hide-error-messages="formDisabled"
            hide-validation-cross
            center-align-errors
            :override-error-message="overrideErrorMessage"
            @keypress="$preventLetterInput($event)"
            @input="handleTranferAmountChange(values)"
          />
          <span
            class="absolute block right-2 top-[2px] bg-transparent z-10 text-slate-100 select-none py-3 pr-1"
          >
            ~${{ transferAmountOutput }}
          </span>
          <div
            v-if="transferAmount && selectedCurrency?.factor > 1"
            class="text-center mb-3 text-xs text-gray-400 -mt-1 font-bold"
          >
            X {{ selectedCurrency?.factor }} = {{ $formatMoney(totalTransferAmount) }} {{ selectedCurrency?.code?.toUpperCase() }}
          </div>
        </div>
      </VForm>
      <div v-if="transferPresets.length" class="flex gap-2">
        <ButtonPreset
          v-for="(preset, index) in transferPresets"
          :key="`preset-${index}`"
          :disabled="formDisabled || preset.disabled"
          :preset="preset.value"
          :selected="selectedPresetIndex === index"
          class="3xs:w-14 xs:w-[62px] text-sm xs:text-base flex-1"
          @click.prevent.stop="handleSelectTransferPreset(preset.value, index)"
        >
          {{ preset.label !== 'MAX' ? Number(preset.label).toLocaleString() : preset.label }}
        </ButtonPreset>
      </div>

      <RewardAppliedCode
        v-show="tab === walletModalModeTypes[0]"
        class="mt-4"
        source="Deposit"
        :code="depositCode"
        />
      <ButtonButton
        :is-loading="isLoading"
        type="submit"
        :disabled="!canSubmit || formDisabled"
        :ignore-disabled-style="isLoading"
        class="block w-full mt-4"
        @click.prevent="handleSubmitWeb3Transfer"
      >
        Transfer
      </ButtonButton>

      <div class="messages">
        <div v-if="emailVerificationRequired" class="content absolute top-0 left-0 bottom-0 right-0 z-20">
            <WalletMessageTransfersRequireEmail
              class="h-full"
              :message="withdrawalAvailabilityDetails.detail"
              @close="emits('close')"
            />
        </div>
        <div v-if="pendingRewardRestriction" class="content absolute top-16 left-0 bottom-0 right-0 z-10">
          <WalletMessagePendingRewardRestriction
              class="h-full"
              :currency="selectedCurrency?.code"
              @close="emits('close')"
            />
        </div>
        <WalletMessageSwitchNetwork
          v-if="
            !!wallet
            && !isCorrectNetwork
            && !!activeNetworkOption
          "
          :network="activeNetworkOption?.name"
          :logo="activeNetworkOption?.icon"
          :error="switchNetworkError"
          @switch-network="handleSwitchNetwork"
          @close="handleCloseSwitchNetwork"
        />
        <WalletMessagesDeposit
          v-if="tab === walletModalModeTypes[0]"
          :tx-hash="txHash"
          :network="activeNetworkOption"
          :error-message="errorMessage"
          :can-user-deposit="canUserDeposit"
          :is-deposit-processing="isDepositProcessing"
        />
        <WalletMessagesWithdrawal
          v-if="tab === walletModalModeTypes[1]"
          :is-loading="isLoading"
          :error-message="errorMessage"
          :selected-currency="selectedCurrency"
          :tx-hash="txHash"
          :can-user-withdraw="canUserWithdraw"
          :withdrawal-details="withdrawalAvailabilityDetails"
          :transfer-amount="transferAmount"
          :network="activeNetworkOption?.name"
          :is-withdrawal-complete="isWithdrawalComplete"
          :is-withdrawal-processing="isWithdrawalProcessing"
          @check-if-withdrawal-available="handleFetchIsWithdrawalAvailable"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { Form as VForm } from 'vee-validate';
import * as yup from 'yup';
import BigNumber from 'bignumber.js';

import { useMessageHandler } from '@/composables/useMessageHandler';
import { useWallet } from '@/composables/useWallet';
import { walletModalModeTypes } from '@/types/Wallet';

const props = defineProps({
  tab: {
    type: String,
    default: '',
  },
  depositCode: {
    type: String,
    default: null,
  },
});

const emits = defineEmits([
  'close',
  'changeTab',
]);

const locationStore = useLocationStore();
const {
  isDepositRestricted,
  isWithdrawalRestricted,
} = storeToRefs(locationStore);

const bankingStore = useBankingStore();
const {
  balances,
  swapsPending,
} = storeToRefs(bankingStore);
const {
  requestWithdrawal,
} = bankingStore;

const userStore = useUserStore();
const {
  userFeatures,
} = storeToRefs(userStore);

const uiStore = useUiStore();
const {
  walletModalFundsContext,
} = storeToRefs(uiStore);
const {
  showTxToastNotification,
} = uiStore;

const authStore = useAuthStore();
const {
  wallet,
} = storeToRefs(authStore);

const blockchainConfigStore = useBlockchainConfigStore();
const {
  getNetworkName,
  getNetworkFromId,
} = blockchainConfigStore;

const cryptoStore = useCryptoStore();
const {
  getTokenBalance,
  walletGet,
  depositTokenAmount,
  startTransaction,
} = cryptoStore;
const {
  balance,
  rates,
} = storeToRefs(cryptoStore);

const depositAccountStore = useDepositAccountStore();
const {
  init: initDepositAccountStore,
} = depositAccountStore;
const {
  accounts,
} = storeToRefs(depositAccountStore);

const websocketStore = useWebsocketStore();

const {
  fetchIsWithdrawalAvailable,
  hasLocalPendingWithdrawals,
  hasLocalPendingDeposits,
  fetchCryptoCurrencyAddress,
} = useWallet();
const {
  $truncateNumber,
  $rollbar,
  $preventLetterInput,
  $convertExponentialNumber,
  $formatMoney,
} = useNuxtApp();

const defaultPresets = [0.05, 0.1, 0.5, 1, 'MAX',];
const formDisabled = ref(false);
const isWithdrawalProcessing = ref(false);
const isWithdrawalComplete = ref(false);
const isDepositProcessing = ref(false);
const txHash = ref('');
const isAddressFetchLoading = ref(false);
const tokenBalance = ref('0');
const withdrawalAvailabilityDetails = ref();
const isLoading = ref(false);
const selectedCurrency = ref();
const transferAmount = ref('');
const activeNetworkOption = ref();
const presetOptions = ref([]);
const errorMessage = ref('');
const canUserDeposit = ref(userFeatures.value?.deposit);
const canSetInitialCurrency = ref(true);
const switchNetworkError = ref(false);
const isToggleLoading = ref(false);
const overrideErrorMessage = ref('');
const selectedPresetIndex = ref(null);
const updateCurrency = ref(false);
const canRefetch = ref(false);

const canSubmit = computed(() =>
  Number(transferAmount.value) > 0
  && (props.tab === walletModalModeTypes[1]
    ? Number(transferAmount.value) <= currentBalance.value?.value
    : Number(transferAmount.value) <= clientWalletBalance.value
  )
  && Number(transferAmount.value) >= Number(minSwap.value)
  && Number(transferAmount.value) <= Number(maxSwap.value)
  && !!selectedCurrency.value
  && !!activeNetworkOption.value
  && (props.tab === walletModalModeTypes[0] ? !isDepositProcessing.value : !isWithdrawalProcessing.value)
);
const transferAmountOutput = computed(() => $truncateNumber((transferAmount.value || 0) * (rates.value?.[selectedCurrency.value?.code]?.USD), 2));
const isToggleBlocked = computed(() => isDepositRestricted.value || isWithdrawalRestricted.value);
const totalTransferAmount = computed(() => new BigNumber(transferAmount.value || 0).multipliedBy(new BigNumber(selectedCurrency.value?.factor)).toNumber());
const currentBalance = computed(() => balances.value.find(item => item.name === selectedCurrency.value?.code && item.context === walletModalFundsContext.value));
const buttons = computed(() => {
  let buttonsLocal = [
    {
      owner: 'client',
      walletName: wallet.value?.name,
      balance: clientWalletBalance.value, // 3rd pary available balance
      currency: currentBalance.value?.name,
      custodialWithdrawal: false,
    },
    {
      owner: 'platform',
      walletName: 'MetaWin',
      balance: withdrawableBalance.value, // My balance
      currency: currentBalance.value?.name,
    },
  ];

  if (props.tab === walletModalModeTypes[1]) {
    buttonsLocal = buttonsLocal.reverse();
  }

  return buttonsLocal;
});
const arrowsRotated = computed(() => buttons.value[0].owner === 'client');
const network = computed(() => currentBalance.value?.config?.defaultNetwork);
const networkOptions = computed(() => {
  if (!currentBalance.value) { return []; }

  return Object.entries(currentBalance.value.config?.networks)
  .filter(([, value,]) => !value.contexts || value.contexts.includes(walletModalFundsContext.value))
  .filter(([key,]) =>
    walletModalFundsContext.value === 'Custodial'
    || !wallet.value
    || (key === 'Solana' && wallet.value.network === 'Solana')
    || (key !== 'Solana' && wallet.value.network !== 'Solana')
  )
  .map(([key,]) => {
    return {
      name: key,
      text: getNetworkName(key),
      icon: key,
    };
  });
});
const canUserWithdraw = ref(userFeatures.value?.withdraw);
const minSwap = computed(() => withdrawalAvailabilityDetails.value?.minimumAmount || currentBalance.value?.config?.deposit?.minAmount);
const maxSwap = computed(() => {
  if (props.tab === walletModalModeTypes[1]) {
    if (withdrawalAvailabilityDetails.value?.limitRemaining) {
      return Math.min(
        withdrawalAvailabilityDetails.value?.limitRemaining,
        currentBalance.value.value
      );
    } else {
      return withdrawalAvailabilityDetails.value?.availableAmount;
    }
  } else {
    return clientWalletBalance.value;
  }
});
const currentBalanceConfig = computed(() => {
  const selectedNetwork = activeNetworkOption.value?.name || network.value;
  return currentBalance.value?.config?.networks?.[selectedNetwork];
});
const isCurrentBalanceToken = computed(() => {
  return currentBalanceConfig.value ? currentBalanceConfig.value?.assetType === 'Token' && currentBalanceConfig.value?.address : false;
});
const clientWalletBalance = computed(() => {
  if (isCurrentBalanceToken.value) {
    return tokenBalance.value;
  }

  return $truncateNumber(balance.value, 7);
});
const withdrawableBalance = computed(() => {
  return new BigNumber(currentBalance.value?.breakdown?.find(item => item.name === 'withdrawable')?.value || 0).toNumber();
});
const transferPresets = computed(() => {
  const presets = presetOptions.value?.map((preset) => {
    return {
      value: preset,
      disabled: false,
      label: preset === 'MAX' ? 'MAX' : `${preset}`,
    };
  });

  presets.forEach((preset) => {
    let disabled = false;
    if (preset.label !== 'MAX') {
      if (
        (Number(preset.value) > clientWalletBalance.value && props.tab === walletModalModeTypes[0])
        || ((Number(preset.value) > withdrawableBalance.value) && props.tab === walletModalModeTypes[1])
        || (props.tab === walletModalModeTypes[1] && Number(preset.value) > maxSwap.value)
      ) {
        disabled = true;
      }
    } else {
      if (
        (props.tab === walletModalModeTypes[0] && clientWalletBalance.value === 0)
        || (props.tab === walletModalModeTypes[1] && withdrawableBalance.value === 0)
      ) {
        disabled = true;
      }
      if (props.tab === walletModalModeTypes[0]) {
        preset.value = clientWalletBalance.value;
      } else {
        preset.value = maxSwap.value > withdrawableBalance.value ? withdrawableBalance.value : maxSwap.value;
      }
    }

    preset.disabled = disabled;
  });

  return presets;
});
const maxSwapErrorMsg = computed(() => {
  switch (true) {
    case !withdrawalAvailabilityDetails.value?.limit:
      return '';
    case new BigNumber(currentBalance.value?.value).isLessThan(withdrawalAvailabilityDetails.value?.limitRemaining):
      return `Maximum withdrawal is ${maxSwap.value} ${selectedCurrency.value?.code?.toUpperCase()}`;
    case withdrawalAvailabilityDetails.value?.isMonthlyLimit:
      return '';
    default:
      return `You have ${withdrawalAvailabilityDetails.value?.limitRemaining} ${selectedCurrency.value?.code?.toUpperCase()} remaining of your 24 hour withdrawal limit. Please select a lower amount or try again later.`;
  }
});
const validationSchema = computed(() => {
  return yup.object().shape({
    transferAmount: yup
    .number()
    .positive()
    .min(minSwap.value, `Minimum ${props.tab === walletModalModeTypes[0] ? 'deposit' : 'withdrawal'} is ${minSwap.value} ${selectedCurrency.value?.code?.toUpperCase()}`)
    .max(maxSwap.value, maxSwapErrorMsg.value)
    .required('Please enter a numeric value')
    .typeError('Please enter a numeric value')
    .label('Amount'),
  });
});
const emailVerificationRequired = computed(() => {
  return withdrawalAvailabilityDetails.value?.outcome === 'VerifiedEmailRequired' || false;
});
const pendingRewardRestriction = computed(() => {
  return withdrawalAvailabilityDetails.value?.outcome === 'PendingRewardRestriction' || false;
});
const isCorrectNetwork = computed(() => {
  if (!wallet.value) { return false; }
  return getNetworkFromId(wallet.value?.chainId) === activeNetworkOption.value?.name;
});
const withdrawAmountPerDayMessage = computed(() => `Withdrawals up to ${Number(withdrawalAvailabilityDetails.value.limit).toLocaleString()} ${currentBalance.value?.name} ${!withdrawalAvailabilityDetails.value?.isMonthlyLimit ? 'per 24h' : 'per 30 day'} period are instant.`);

if (!accounts.value.length) {
  await initDepositAccountStore();
}

onMounted(async() => {
  await setInitialPresets();
});

websocketStore.$onAction(({ name, args, }) => {
  if (name === 'handleMessage' && args[0].type === 'Withdrawal:StatusChange') {
    useMessageHandler({ name, args, }, handleWebsocketWithdrawalUpdate, 'Withdrawal:StatusChange');
  }
});

function handleSetInitialNetworkOption() {
  const networkName = getNetworkFromId(wallet.value?.chainId);
  activeNetworkOption.value = networkOptions.value.find(o => o.name === networkName) ?? networkOptions.value[0];
}

async function handleCurrencyChange(currency) {
  selectedCurrency.value = currency;
  if (canSetInitialCurrency.value) {
    handleSetInitialNetworkOption();
    canSetInitialCurrency.value = false;
  } else if (!isCorrectNetwork.value) {
    activeNetworkOption.value = {
      name: network.value,
      text: getNetworkName(network.value),
      icon: network.value,
    };
  } else if (currentBalance.value.name === selectedCurrency.value?.code) {
    handleSetInitialNetworkOption();
  }

  await setInitialPresets();

  if (props.tab === walletModalModeTypes[1]) {
    isWithdrawalProcessing.value = hasLocalPendingWithdrawals(selectedCurrency.value?.code);

    if (!response.items?.length && !isWithdrawalProcessing.value) {
      await handleFetchIsWithdrawalAvailable();
    } else {
      formDisabled.value = true;
      isWithdrawalProcessing.value = true;
    }
  }
  handleFormReset();
  await updateClientBalance();
}

async function handleToggleTransferDirection(override = false) {
  handleFormReset();
  isToggleLoading.value = true;
  formDisabled.value = false;
  if (override) {
    await setInitialInversePresets(override);
  } else {
    await setInitialPresets();
  }
  await updateClientBalance();
  isToggleLoading.value = false;
}

async function setInitialPresets() {
  presetOptions.value = [];
  if (props.tab === walletModalModeTypes[0]) {
    setDepositPresets();
  } else {
    await setWithdrawalPresets();
  }
}

function setDepositPresets() {
  const options = currentBalance.value?.config?.deposit?.options;
  if (options?.length > 0) {
    presetOptions.value = [
      ...options,
      'MAX',
    ];
  } else {
    presetOptions.value = [...defaultPresets,];
  }
}

async function setWithdrawalPresets() {
  await handleFetchIsWithdrawalAvailable(true);

  if (withdrawalAvailabilityDetails.value?.defaultAmounts) {
    presetOptions.value = [...withdrawalAvailabilityDetails.value.defaultAmounts, 'MAX',];
  }
}

async function setInitialInversePresets(override = false) {
  if (props.tab === walletModalModeTypes[0]) {
    await setWithdrawalPresets();
  } else {
    setDepositPresets();
  }

  if (override) {
    canRefetch.value = false;
    emits('changeTab', walletModalModeTypes[props.tab === walletModalModeTypes[0] ? 1 : 0]);
  }
}

async function handleUpdateNetworkOption(option) {
  if (option.name !== activeNetworkOption.value.name) {
    activeNetworkOption.value = {
      name: option.name,
      text: getNetworkName(option.name),
      icon: option.name,
    };

    await updateClientBalance();

    if (props.tab === walletModalModeTypes[0] && !isDepositRestricted.value) {
      isAddressFetchLoading.value = true;
      const response = await fetchCryptoCurrencyAddress(currentBalance.value.name, currentBalance.value.config.defaultNetwork);

      if (!response.error) {
        address.value = response.address;
        dataUrl.value = response.dataUrl;
      } else {
        errorMessage.value = response.error;
      }
      isAddressFetchLoading.value = false;
    }
  }
}

async function handleSubmitWeb3Transfer() {
  if (isDepositRestricted.value) { return; }
  let txAmount = new BigNumber(transferAmount.value || 0);

  isLoading.value = true;
  formDisabled.value = true;
  try {
    // Deposit
    if (props.tab === walletModalModeTypes[0]) {
      txAmount = txAmount.multipliedBy(selectedCurrency.value?.factor || 1).toString();

      if (currentBalanceConfig.value?.assetType === 'Token' && currentBalanceConfig.value?.address) {
        txHash.value = await depositTokenAmount(currentBalanceConfig.value.address, txAmount, activeNetworkOption.value?.name);
      } else {
        txHash.value = await startTransaction(txAmount, activeNetworkOption.value?.name);
      }

      swapsPending.value.unshift({ txHash: txHash.value, currencyCode: selectedCurrency.value?.code, direction: 'in', fundsContext: walletModalFundsContext.value, });
      if (txHash.value) {
        showTxToastNotification('Pending', walletModalModeTypes[0], walletModalFundsContext.value);
      }
    } else {
      // Withdraw
      const response = await requestWithdrawal(
        selectedCurrency.value?.code,
        txAmount,
        undefined,
        walletModalFundsContext.value,
        activeNetworkOption.value?.name,
        undefined
      );
      if (response.status === 'Pending') {
        showTxToastNotification('Pending', 'Withdrawal', walletModalFundsContext.value);
      }
    }
    emits('close');
  } catch (err) {
    $rollbar.error(err.code === 4001 ? `${props.tab} Failed` : 'Method failed', err);
  } finally {
    isLoading.value = false;
    formDisabled.value = false;
  }
}

function handleSelectTransferPreset(value, index = null) {
  if (Number(value) > Number(clientWalletBalance.value) && props.tab === walletModalModeTypes[0]) {
    overrideErrorMessage.value = 'Insufficient Funds';
  } else if ((Number(value) > Number(withdrawableBalance.value)) && props.tab === walletModalModeTypes[1]) {
    overrideErrorMessage.value = 'Insufficient Funds';
  } else {
    overrideErrorMessage.value = '';
  }
  transferAmount.value = value > 0 ? $convertExponentialNumber(value) : 0;

  const i = transferPresets.value.findIndex(preset => Number(preset.value) === Number(value));
  selectedPresetIndex.value = i > -1 ? i : index;
}

async function handleFetchIsWithdrawalAvailable(override = false) {
  if (props.tab === walletModalModeTypes[0] && !override) {
    return;
  }
  try {
    errorMessage.value = '';
    const response = await fetchIsWithdrawalAvailable(selectedCurrency.value?.code, transferAmount.value || undefined);
    withdrawalAvailabilityDetails.value = response;
    if (withdrawalAvailabilityDetails.value?.outcome !== 'Available') {
      formDisabled.value = true;
      if (withdrawableBalance.value !== 0) {
        errorMessage.value = withdrawalAvailabilityDetails.value?.detail;
      } else {
        errorMessage.value = 'Insufficient Funds.';
      }
    } else {
      formDisabled.value = false;
    }
  } catch (err) {
    console.log('handleFetchIsWithdrawalAvailable failed:::', err);
  }
}

async function updateClientBalance() {
  if (!wallet.value) { return; }

  if (isCurrentBalanceToken.value) {
    const selectedNetwork = activeNetworkOption.value?.name || network.value;
    tokenBalance.value = await getTokenBalance(currentBalance.value.config.networks[selectedNetwork].address);
  } else {
    await walletGet();
  }
}

function handleTranferAmountChange(values) {
  handleSelectTransferPreset(values.transferAmount);
}

async function handleWebsocketWithdrawalUpdate(payload) {
  if (['Confirmed', 'Approved',].includes(payload.status)) {
    emits('close');
  } else if (['Cancelled', 'Declined', 'Error', 'InsufficientFunds',].includes(payload.status)) {
    isWithdrawalProcessing.value = false;
  } else if (payload.status === 'Complete') {
    isWithdrawalProcessing.value = false;
    isWithdrawalComplete.value = true;
    await resetRequestValue();
  }

  txHash.value = payload?.providerRef;
}

async function resetRequestValue() {
  transferAmount.value = '';
  if (props.tab === walletModalModeTypes[1]) {
    await handleFetchIsWithdrawalAvailable();
  }
}

async function handleSwitchNetwork() {
  switchNetworkError.value = false;
  await wallet.value?.checkChain(activeNetworkOption.value?.name);
  await updateClientBalance();
}

function handleCloseSwitchNetwork() {
  const networkName = getNetworkFromId(wallet.value?.chainId);
  switchNetworkError.value = false;
  if (networkName) {
    handleSetInitialNetworkOption();
    updateCurrency.value = !updateCurrency.value;
  } else {
    switchNetworkError.value = true;
  }
}

function handleFormReset() {
  transferAmount.value = '';
  selectedPresetIndex.value = null;
  overrideErrorMessage.value = '';
  errorMessage.value = '';
}

watch(
  () => props.tab,
  async() => {
    if (canRefetch.value) {
      await handleToggleTransferDirection();
    } else {
      canRefetch.value = true;
    }
  },
  { immediate: true, }
);

watch(
  () => swapsPending.value,
  () => {
    isWithdrawalProcessing.value = hasLocalPendingWithdrawals(selectedCurrency.value?.code);
    isDepositProcessing.value = hasLocalPendingDeposits(selectedCurrency.value?.code);
  }
);
</script>

export const BlockchainNetwork = {
    Ethereum: 'Ethereum',
    Bitcoin: 'Bitcoin',
    Arbitrum: 'Arbitrum',
    Solana: 'Solana',
    Litecoin: 'Litecoin',
    Dogecoin: 'Dogecoin',
    BitcoinCash: 'BitcoinCash',
    Ripple: 'Ripple',
    Tron: 'Tron',
    Polygon: 'Polygon',
    EOS: 'EOS',
    Binance: 'Binance',
    Base: 'Base',
    Optimism: 'Optimism',
    Blast: 'Blast'
}

export const EVMBlockchainNetworks = [
    BlockchainNetwork.Ethereum,
    BlockchainNetwork.Arbitrum,
    BlockchainNetwork.Optimism,
    BlockchainNetwork.Polygon,
    BlockchainNetwork.Blast
];
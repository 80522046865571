export const useWeeklyRaffleStore = defineStore('weeklyRaffleStore', () => {
  const { $api, } = useNuxtApp();
  const raffleId = ref(null);
  const raffleInfo = ref(null);
  const isLoading = ref(false);
  const raffleEndTime = ref(null);
  const tickets = ref([]);

  async function getRaffleId() {
    try {
      isLoading.value = true;
      const scheduleName = 'weekly-raffle';
      const data = await $api(`/sweepstake/leaderboard/schedule/${scheduleName}/item`, {
        method: 'GET',
      });
      raffleEndTime.value = data.endTime;
      raffleId.value = data.leaderboardId;
      await fetchRaffleById(raffleId.value);
    } finally {
      isLoading.value = false;
    }
  }

  function mapTickets(data) {
    if (!data) { return []; }
    const raffleName = data?.name;
    const gameBuyInBalance = data.userEntry?.gameBuyInBalance;
    if (!gameBuyInBalance) { return []; }
    return Array.from({ length: gameBuyInBalance.entryCount, }, (_, index) => ({
      id: index + 1,
      prize: raffleName,
      number: Math.floor(Math.random() * 1000000) + 100000,
    })).sort((a, b) => b.number - a.number);
  }

  async function fetchRaffleById(id) {
    try {
      const data = await $api(`/sweepstake/leaderboard/${id}`, {
        method: 'GET',
      });
      raffleInfo.value = data;
      tickets.value = mapTickets(data);
    } catch (error) {
      raffleInfo.value = null;
    }
  }

  return {
    getRaffleId,
    tickets,
    raffleInfo,
    isLoading,
    raffleEndTime,
  };
});

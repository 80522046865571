export function useCountdown() {
  const { $dayjs, } = useNuxtApp();
  const workers = reactive(new Map());
  function createCountdown(id, date, updateFrequencyInSeconds = 1) {
    const countdown = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      isFinished: false,
    });

    // Terminate previous worker for this ID if it exists
    if (workers.has(id)) {
      workers.get(id).terminate();
      workers.delete(id);
    }

    const worker = new Worker(new URL('./countdown.worker.js', import.meta.url));
    workers.set(id, worker);

    worker.onmessage = (e) => {
      if (e.data.finished) {
        countdown.value = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          isFinished: true,
        };
        worker.terminate();
        workers.delete(id);
      } else {
        countdown.value = {
          days: e.data.days,
          hours: e.data.hours,
          minutes: e.data.minutes,
          seconds: e.data.seconds,
          isFinished: false,
        };
      }
    };

    worker.postMessage({
      targetDate: $dayjs(date).toDate().getTime(),
      updateFrequencyInSeconds,
    });

    return {
      countdown,
      stop: () => {
        if (workers.has(id)) {
          workers.get(id).terminate();
          workers.delete(id);
        }
      },
    };
  }

  function formatCountdown(
    countdown,
    options = {
      format: 'DD[d] : HH[h] : mm[m] : ss[s]',
      initialText: 'Calculating...',
      finishedText: 'Time is up!',
    }
  ) {
    if (!countdown || (!countdown.isFinished && !countdown.days && !countdown.hours && !countdown.minutes && !countdown.seconds)) {
      return options.initialText;
    }
    if (countdown.isFinished) {
      return options.finishedText;
    }
    return $dayjs.duration(countdown).format(options.format);
  }

  // Method to check if countdown with given ID exists
  function hasCountdown(id) {
    return workers.has(id);
  }

  // Method to stop specific countdown
  function stopCountdown(id) {
    if (workers.has(id)) {
      workers.get(id).terminate();
      workers.delete(id);
    }
  }

  function stopAllCountdowns() {
    for (const worker of workers.values()) {
      worker.terminate();
    }
    workers.clear();
  }

  onUnmounted(() => {
    stopAllCountdowns();
  });

  return {
    createCountdown,
    hasCountdown,
    stopCountdown,
    stopAllCountdowns,
    formatCountdown,
  };
}

<template>
    <header class="w-full text-center mb-3">
      <h2 class="font-semibold text-base">{{ gameFairnessData?.name }}</h2>
      <p class="font-semibold text-base pb-6">ID {{ gameFairnessData.roundId }}</p>
      <p class="text-xs text-slate-light pb-1">Placed by {{ userData?.displayName }}</p>
      <p class="text-xs text-slate-light">on {{ $dayjs(gameFairnessData?.timestamp).format('MM/DD/YYYY [at] h:mm A') }}</p>
    </header>
    <div class="w-full text-center px-4 bg-slate-600 rounded-lg">
      <div class="py-5 text-xs font-bold">
        <p class="text-slate-light pb-1">Bet</p>
        <p class="flex justify-center h-4">
          {{ gameFairnessData?.buyin }}
          <CurrencyIconImage
            :currency="currency.toUpperCase()"
            class="ml-1"
            width="30"
            height="30"
          />
        </p>
      </div>
      <div class="pb-5 text-xs font-bold">
        <p class="text-slate-light pb-1">Multiplier</p>
        <p>{{ multiplier }}x</p>
      </div>
      <div class="pb-5 text-xs font-bold">
        <p class="text-slate-light pb-1">Payout</p>
        <p class="flex justify-center h-4">
          {{ gameFairnessData?.payout }}
          <CurrencyIconImage
            :currency="currency.toUpperCase()"
            class="ml-1"
            width="30"
            height="30"
          />
        </p>
      </div>
    </div>
    <h3 class="text-xs pt-4 text-slate-light text-center">Provable Fairness</h3>
    <div class="text-xs py-3">
      <FormsFieldWithLabel
        v-for="(data, index) in seedDataTransformed"
        :key="`${data?.name}-${index}`"
        :label="data.title"
        :model-value="data.text"
        copyable
        readonly
        class="pb-3"
      />
    </div>
</template>

<script setup>
const gameStore = useGamesStore();
const { gameFairnessData, } = storeToRefs(gameStore);
const userStore = useUserStore();
const { userData, } = storeToRefs(userStore);
const fairnessStore = useFairnessVerificationStore();
const { rawSeedData, currency, multiplier, } = storeToRefs(fairnessStore);

const { $dayjs, } = useNuxtApp();

const seedDataTransformed = computed(() => {
  if (!rawSeedData.value) {
    return [];
  }
  return [
    {
      title: 'Server Seed',
      text: rawSeedData.value.serverSeed || "Seed hasn't been revealed yet",
    },
    {
      title: 'Server Seed (Hashed)',
      text: rawSeedData.value.serverSeedHash,
    },
    {
      title: 'Client Seed',
      text: rawSeedData.value.clientSeed,
    },
    {
      title: 'Nonce',
      text: rawSeedData.value.nonce,
    },
  ];
});

</script>

const imports = import.meta.glob([
  '~/assets/icons/**/*.svg',
  '~/assets/img/icons-figma-export/**/*.svg',
], { as: 'raw', eager: true, });

export const icons = Object.entries(imports).reduce((formattedIcons, icon) => {
  const name = icon[0].split('/').pop()?.replace('.svg', '');
  return {
    [name]: icon[1],
    ...formattedIcons,
  };
}, {});

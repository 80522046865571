<template>
  <div class="keno-container grid grid-cols-8 gap-2">
    <div
      v-for="i in 40"
      :key="i"
      class="keno-cell w-[38px] h-[38px] flex items-center justify-center rounded-lg text-[21px] font-bold"
      :class="gameResult.includes(i - 1) ? 'shadow-[0px_2px_0px_0px_#252831,0px_2px_0px_0px_rgba(20,2,2,0.5)_inset] text-[#fc2b2b] bg-[#14020280]' : 'bg-[#0f1115] shadow-[0px_2px_0px_0px_#000] text-[#d0d0d0]'"
    >
      {{ i }}
    </div>
  </div>
</template>

<script setup>
const fairnessStore = useFairnessVerificationStore();
const { kenoResult, } = fairnessStore;

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
});

const gameResult = computed(() => kenoResult(props.result));
</script>

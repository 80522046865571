<template>
  <div
    :class="[ alterativeLayout && isUserLogged ? 'top-[22px]' : 'top-0']"
    class="hidden lg:block sticky z-40 left-0 w-full nav-top transition-all duration-100"
  >
    <div
      class="bg-[#0F0E0E] flex items-center justify-between shadow-black/40 transition-all relative h-[70px]"
      :class="{ 'shadow-lg': scrollActive }"
    >
      <NavigationBurgerButton
        class="ml-2"
        @click.prevent.stop="toggleNav"
      />

      <button
        class="w-full max-w-[150px] items-center ml-3 fade-in flex relative h-full overflow-hidden"
        @click.prevent.stop="handleRedirect"
      >
        <div class="relative flex">
          <SvgIcon
            icon="metawin-logo-white"
            width="150"
            height="24"
          />
          <img
            v-if="showHalloweenLogo"
            src="~/assets/img/promos/halloween-animation.gif"
            alt="Halloween"
            width="40"
            height="40"
            class="inline-block"
          >
          <img
            v-if="showChristmasLogo"
            src="~/assets/img/promos/christmas-animation.gif"
            alt="Halloween"
            width="40"
            height="40"
            class="inline-block absolute left-[35%] -top-[5px]"
          >
        </div>
        <img
          v-if="showChristmasLogo"
          src="~/assets/img/promos/snow.gif"
          alt="Halloween"
          width="150"
          height="87"
          class="absolute inset-0 inline-block"
        >
      </button>

      <div class="flex items-center justify-end mr-2 ml-auto">
        <BalanceMainNav v-if="isUserLogged" hover-enabled/>

        <NuxtLink
          v-if="isUserLogged && userProfileUrl"
          :to="userProfileUrl"
          :replace="route.fullPath.includes('sports')"
          class="ml-5"
        >
          <img
            class="rounded-full w-8 h-8 object-cover"
            :class="{ 'ring-2 ring-red-600 ring-opacity-80': isSalonRougePlayer }"
            :src="userData?.avatarUrl"
            alt="Your avatar"
            decoding="async"
          >
        </NuxtLink>

        <ButtonConnect
          v-if="!isUserLogged"
          theme="loadmore"
          size="xs"
          text="Sign In"
          class="font-bold"
        />
        <ButtonConnect
          v-if="!isUserLogged"
          theme="primary"
          size="xs"
          text="Register"
        />

        <button
          v-if="showChat"
          class="text-slate-400 text-lg ml-2 hover:text-white focus:text-slate-400 transition-colors px-3 py-1"
          @click.prevent.stop="toggleChatState"
        >
          <span :class="chatState === 'fullscreen' ? 'icon-chat-disabled' : 'icon-chat'"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useNavigation } from '@/composables/useNavigation';

defineProps({
  alterativeLayout: {
    type: Boolean,
    default: false,
  },
});
const uiStore = useUiStore();
const {
  chatState,
  showChat,
  navState,
} = storeToRefs(uiStore);
const {
  toggleChatState,
  setNavState,
} = uiStore;

const authStore = useAuthStore();
const {
  isUserLogged,
} = storeToRefs(authStore);

const userStore = useUserStore();
const {
  userData,
  userProfileUrl,
  isSalonRougePlayer,
} = storeToRefs(userStore);

const { sportsBettingRedirect, } = useNavigation();
const route = useRoute();

const scrollPosition = ref(0);
const activateScrollAt = ref(20);
const showHalloweenLogo = ref(false);
const showChristmasLogo = ref(false);

const scrollActive = computed(() => scrollPosition.value >= activateScrollAt.value);

function handleRedirect() {
  switch (route.name) {
    case 'live-games-id':
      return sportsBettingRedirect('/live-games');
    case 'games-id':
    case 'minigames-id':
      return sportsBettingRedirect('/games');
    default:
      return sportsBettingRedirect('/');
  }
}

function updateScroll() {
  scrollPosition.value = window.scrollY;
}

function toggleNav() {
  switch (navState.value) {
    case 'mini':
      setNavState('full');
      break;
    case 'full':
      if (window.innerWidth > 1024) {
        setNavState('mini');
        return;
      }

      setNavState('hidden');
      break;
    case 'hidden':
      setNavState('full');
      break;
  }
}

onMounted(() => {
  window.addEventListener('scroll', updateScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateScroll);
});
</script>

<template>
  <div class="dragon-tower-container h-[200px] relative flex flex-col items-center">
    <img :src="towerUrl" alt="tower" class="h-full">
    <div class="absolute bottom-1 w-[134px] h-[142px] grid gap-[2px]" :class="`grid-cols-${columns}`">
      <div
        v-for="(dragonResult, index) in gameResult"
        :key="index"
        class="dragon-tower-cell h-[10.6px] flex items-center justify-center border rounded-[1px] shadow-[0_1px_0_0_#000] bg-[#252831]"
        :class="dragonResult ? 'border-red-600' : 'border-slate-light'"
      >
        <img
          v-if="dragonResult"
          :src="skullUrl"
          alt="skull"
          class="h-[9.54px] w-[10.6px] translate-y-[-10%]"
        >
        <img
          v-else
          :src="eggUrl"
          alt="egg"
          class="h-[10.6px] w-[10.6px] translate-y-[-10%]"
        >
      </div>
    </div>
  </div>
</template>

<script setup>
const fairnessStore = useFairnessVerificationStore();
const { dragonTowerResult, } = fairnessStore;

const gameStore = useGamesStore();
const { assetUrl, } = storeToRefs(gameStore);

const props = defineProps({
  result: {
    type: Object,
    required: true,
  },
  difficulty: {
    type: String,
    default: 'easy',
    validator: value => ['easy', 'medium', 'hard', 'expert', 'master',].includes(value),
  },
});

const towerUrl = computed(() => `${assetUrl.value}/dragon-tower/assets/history/dragon-tower/tower.svg`);
const eggUrl = computed(() => `${assetUrl.value}/dragon-tower/assets/history/dragon-tower/egg.svg`);
const skullUrl = computed(() => `${assetUrl.value}/dragon-tower/assets/history/dragon-tower/skull.svg`);
const gameResult = computed(() => dragonTowerResult(props.result, props.difficulty));

const columns = computed(() => dragonTowerRowConfigs[props.difficulty].columns);
</script>

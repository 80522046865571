<template>
  <ModalMainNew
    modal-width-mode="3xl"
    show-close-mobile
    secondary-background-color
    @close="onClose"
  >
    <div class="pb-5 flex flex-col gap-6 animate-slide-down-fade-in01s">
      <h2 class="text-xl font-bold text-center uppercase">ROI Calculator</h2>
      <p v-if="false && asset.type === 'Algorithmic'" class="text-sm text-gray-400">
        {{ asset.name }} has {{ houseEdge }}% house edge and a {{ fee }}% fee on profitable bets.
      </p>
      <div class="flex gap-4 flex-col md:flex-row">
        <div
          class="flex flex-col form w-full md:w-72 gap-4 mt-1 text-xs text-slate-100"
        >
          <div class="flex flex-col gap-2">
            <div class="flex justify-between items-center">
              <p class="font-bold">
                Entry Price
              </p>
              <DropdownMain
                v-if="assetOptions.length > 0"
                :options="assetOptions"
                :active-option="assetActiveOption"
                theme="input-small"
                icon-size="16"
                :disabled="assetOptions.length === 1"
                :hide-arrow="assetOptions.length === 1"
                @select="onSelectChange"
              />
              {{ assetActiveOption.value }}
            </div>
            <input
              v-model="entryPrice"
              type="number"
              min="1"
              class="w-full text-white text-sm bg-black/50 rounded-md border-none py-3 px-4 outline-none focus:ring-0 appearance-none hover:appearance-none transition-all tabular-nums"
              @keydown="onEntryPriceKeydown"
            >
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex align-items-center uppercase font-semibold">
              Will the price go up or down?
            </div>
            <div class="flex">
              <div
                class="inline-flex align-items-center gap-1 sm:gap-2 bg-black/50 p-[6px] rounded-md overflow-x-scroll scrollbar-hide w-full"
              >
                <h2
                  class="relative py-2 px-3 sm:px-4 w-1/2 rounded-md font-semibold tracking-normal sm:tracking-wider text-xs leading-none category-title mb-0 cursor-pointer hover:bg-green-400/20 hover:text-green-500 bg-slate-600/25 text-slate-200 text-center"
                  :class="{ '!text-green !bg-green-400/20': isLong }"
                  @click.prevent.stop="onTogglePosition(true)"
                >
                  <div
                    class="flex items-center justify-center text-center"
                  >
                    <SvgIcon
                      width="14"
                      height="14"
                      icon="caret-up-fill"
                      class="mr-2"
                    />
                    <span>Up</span>
                  </div>
                </h2>
                <h2
                  class="relative py-2 px-3 sm:px-4 w-1/2 rounded-md font-semibold tracking-normal sm:tracking-wider text-xs leading-none category-title mb-0 cursor-pointer hover:bg-red-600/30 hover:text-red bg-slate-600/25 text-slate-200 text-center"
                  :class="{ '!text-red !bg-red-500/30': !isLong }"
                  @click.prevent.stop="onTogglePosition(false)"
                >
                  <div
                    class="flex items-center justify-center text-center"
                  >
                    <SvgIcon
                      width="14"
                      height="14"
                      icon="caret-down-fill"
                      class="mr-2"
                    />
                    <span>Down</span>
                  </div>
                </h2>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex align-items-center uppercase font-semibold">
              Wager
            </div>
            <div class="flex text-xs bg-black/50 rounded-md">
              <div class="relative flex justify-center items-center text-sm pl-2">
                $
              </div>
              <input
                v-model="stake"
                class="block text-white text-sm relative w-full rounded-md py-3 px-4 mr-2 outline-none focus:ring-0 appearance-none hover:appearance-none disabled:text-slate-100 text-left transition-all duration-200 bg-transparent border-none autofill:bg-slate-900 tabular-nums"
                type="number"
                min="0.01"
              >
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex align-items-center uppercase font-semibold">
              Payout Multiplier
            </div>
            <div class="flex text-xs">
              <div class="flex w-full bg-black/50 rounded-md">
                <div
                  class="relative flex justify-center items-center text-base pl-2"
                >
                  &times;
                </div>
                <input
                  v-model="leverage"
                  class="block text-white text-sm relative w-full rounded-md py-3 px-4 mr-2 outline-none focus:ring-0 appearance-none hover:appearance-none disabled:text-slate-100 text-left transition-all duration-200 bg-transparent border-none autofill:bg-slate-900 tabular-nums"
                  type="number"
                  min="1"
                  max="1000"
                  step="1"
                >
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-2">
            <div class="flex align-items-center uppercase font-semibold">
              Custom Price Move %
            </div>
            <div class="flex text-xs">
              <input
                v-model="customPriceMove"
                class="w-full text-white text-sm !bg-black/50 rounded-md border-none py-3 px-4 outline-none focus:outline-none transition-all autofill:bg-black/50 focus:ring-0 appearance-none hover:appearance-none"
                type="number"
              >
            </div>
          </div>
          <ButtonButton
            theme="grey"
            class="w-full !py-3"
            @click="onResetFields"
          >
            Reset fields
          </ButtonButton>
        </div>
        <div class="flex-1 overflow-x-auto lg:overflow-x-visible">
          <table
            class="text-sm text-slate-100 border-separate border-spacing-y-1 w-[447px] animate-slide-down-fade-in05s"
          >
            <thead>
              <tr>
                <th
                  v-for="(header, i) in headers"
                  :key="header"
                  class="bg-gray-800 bg-opacity-50 py-2 px-4 whitespace-nowrap text-right text-xs"
                  :class="{
                    'rounded-l-md': i === 0,
                    'rounded-r-md': i === headers.length - 1,
                  }"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, i) in tableData"
                :key="i"
                class="bg-gray-800 bg-opacity-50 rounded-md"
              >
                <td
                  v-for="(value, j) in row"
                  :key="value + j"
                  class="py-1 px-4 text-right tabular-nums"
                  :class="{
                    'rounded-l-md': j === 0,
                    'rounded-r-md': j === headers.length - 1,
                    'text-green-500': isPositive(row),
                    'text-red-500': !isPositive(row),
                    'text-white': ['$0.00', '-$0.00', '--'].includes(row[3]),
                  }"
                >
                  {{ value }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ModalMainNew>
</template>

<script setup>
import { BigNumber } from 'bignumber.js';

import { calculatePnl } from '@/utils/trading/calculatePnl';

const route = useRoute();
const tradingStore = useTradingStore();

const {
  assets,
} = storeToRefs(tradingStore);

const {
  findAsset,
} = tradingStore;

const uiStore = useUiStore();

const {
  showTradingRoiModal,
} = storeToRefs(uiStore);

const {
  $formatMoney,
  $toFixed,
  $toCurrencyString,
  $isEqualIgnoreCase,
} = useNuxtApp();

const assetCode = ref(route.params.asset);
const assetList = ref([...assets.value.values(),]);
const assetOptions = computed(() => assetList.value?.map(a => ({ text: a.code, icon: a.code, })));
const assetActiveOption = computed(() => assetOptions.value.find(o => $isEqualIgnoreCase(o.text, assetCode.value)) || assetOptions.value[0]);
const asset = computed(() => findAsset(assetActiveOption.value.text));
const entryPrice = ref(asset.value.price || 0);
const isLong = ref(true);
const stake = ref(1000);
const leverage = ref(1);
const customPriceMove = ref(null);
const headers = ['PRICE MOVE', 'ROI', 'EXIT PRICE', 'P&L',];
const priceMoveSteps = [-100, -50, -10, -5, -1, -0.5, -0.1, -0.05, -0.01, 0, 0.01, 0.05, 0.1, 0.5, 1, 5, 10, 50, 100, 500, 1000,];
const priceMoves = computed(() => customPriceMove.value ? [customPriceMove.value,] : priceMoveSteps);
const tableData = computed(() => priceMoves.value?.map(priceMove => buildTableData(priceMove)));
const houseEdge = computed(() => new BigNumber(asset.value.baseRate).dividedBy(2).multipliedBy(100));
const fee = computed(() => new BigNumber(asset.value.baseRate).multipliedBy(100));

function isPositive(row) {
  const priceMove = row[0];
  const isLongValue = isLong.value;
  const priceMoveValue = parseFloat(priceMove);
  return isLongValue ? priceMoveValue > 0 : priceMoveValue < 0;
}

function buildTableData(priceMove) {
  let exitPrice = 0;
  let roi = (stake.value && leverage.value) ? -100 : undefined;
  let pnl = (stake.value && leverage.value) ? -stake.value : undefined;

  if (entryPrice?.value > 0 && stake.value && leverage.value) {
    const orderEntryPrice = $toFixed(entryPrice.value, asset.value.decimals);
    const orderExitPrice = orderEntryPrice * (1 + (priceMove / 100));

    exitPrice = $toFixed(orderExitPrice, asset.value.decimals);

    const order = {
      assetCode: asset.value.code,
      isLong: isLong.value,
      leverage: leverage.value,
      entryPrice: orderEntryPrice,
      exitPrice,
      stake: {
        baseAmount: stake.value,
      },
    };

    const result = calculatePnl(asset.value, order);
    roi = result.roi;
    pnl = result.pnl;
  }

  return [
    `${priceMove}%`,
    typeof roi === 'number' ? `${$toFixed(roi)}%` : '--',
    $formatMoney(exitPrice, 'USD'),
    typeof pnl === 'number' ? $toCurrencyString(pnl) : '--',
  ];
}

function onTogglePosition(val) {
  isLong.value = val;
}

function onResetFields() {
  assetCode.value = route.params.asset || assetOptions.value[0].text;
  entryPrice.value = asset.value.price;
  isLong.value = true;
  stake.value = 1000;
  leverage.value = 1;
  customPriceMove.value = null;
}

function onSelectChange(option) {
  assetCode.value = option.text;
  entryPrice.value = asset.value.price;
}

function onClose() {
  showTradingRoiModal.value = false;
}

function onEntryPriceKeydown(event) {
  if (event.key === '-') {
    event.preventDefault();
  }
}

watch(() => entryPrice.value, (value, oldValue) => {
  if (value > 10000000) {
    entryPrice.value = oldValue;
  }
});

watch(() => stake.value, (value, oldValue) => {
  if (value > 1000000) {
    stake.value = oldValue;
  }
});

watch(() => leverage.value, (value, oldValue) => {
  if (value === '') {
    return;
  }

  if (value > asset.value.leverageLimit || value < 1) {
    leverage.value = oldValue;
  }
});

watch(() => customPriceMove.value, (value, oldValue) => {
  if (value > 100000) {
    customPriceMove.value = oldValue;
  }
});
</script>

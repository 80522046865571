<template>
  <transition :name="transitionNameComp">
    <Teleport to="body">
      <div
        ref="container"
        :class="{
          'cursor-pointer': !hideClose,
          'z-60': priority,
          'z-50': !priority,
          'pb-0 sm:pb-3' : transitionNameComp === 'modal-slide-up'
        }"
        class="flex justify-center items-center fixed inset-0"
        @mouseup="deactivateDrag"
      >
        <div class="mask absolute top-0 left-0 w-full h-full bg-black/80" @click.prevent="closeModal"/>
        <div
          ref="model"
          class="absolute bottom-0 sm:relative"
          :style="{ bottom: `${bottom}px` }"
          @mousedown.stop="activateDrag"
          @mousemove.stop="drag"
          @mouseleave.stop="reset"
          @touchstart.stop="activateDrag"
          @touchmove.stop="drag"
          @touchend.stop="deactivateDrag"
        >
          <div
            v-if="isBottomSlider"
            class="w-screen flex p-4 pb-2 h-20 bg-transparent select-none"
          >
            <span class="w-14 h-1 m-auto bg-white/40 rounded-full mb-0 cursor-grab"/>
          </div>
          <div class="container" :class="modalClassList">
            <div
              v-if="!hideClose && showCloseMobile"
              class="flex justify-end pb-2 xs:pb-0 transition-all"
              :class="{ 'absolute top-2 right-2' : absoluteCloseButton }"
            >
              <div
                v-if="modalHeader"
                :class="[ showHeaderIcon ? 'justify-start' : 'justify-center' ]"
                class="flex items-center font-semibold w-full text-xs"
              >
                <SvgIcon
                  v-if="showHeaderIcon"
                  :icon="modalHeaderIcon"
                  width="18"
                  height="18"
                  class="mr-1 text-slate-light"
                />
                <span
                  :class="{ 'ml-[32px]' : !showHeaderIcon}"
                  class="text-slate-light"
                >
                  {{ modalHeader }}
                </span>
              </div>
              <button
                class="z-10 justify-center items-center top-2 right-2 w-8 h-8 transition-all animate-slide-down-fade-in07s group"
                :class="{ 'hidden xs:flex': !showCloseMobile, 'bg-black/20 rounded-full hover:bg-black/40' : absoluteCloseButton }"
                @click.prevent="closeModal"
              >
                <span class="icon-ico-x text-sm text-white/70 group-hover:text-white/90"/>
              </button>
            </div>
            <slot/>
          </div>
        </div>
      </div>
    </Teleport>
  </transition>
</template>

<script setup>
import { useDraggable, useSwipe } from '@vueuse/core';

const props = defineProps({
  mediaType: {
    type: String,
    default: null,
  },
  modalWidthMode: {
    type: String,
    default: '',
  },
  priority: {
    type: Boolean,
    default: false,
  },
  hideClose: {
    type: Boolean,
    default: false,
  },
  transitionName: {
    type: String,
    default: 'modal',
  },
  showCloseMobile: {
    type: Boolean,
    default: false,
  },
  absoluteCloseButton: {
    type: Boolean,
    default: false,
  },
  secondaryBackgroundColor: {
    type: Boolean,
    default: false,
  },
  modalHeader: {
    type: String,
    default: '',
  },
  showHeaderIcon: {
    type: Boolean,
    default: false,
  },
  modalHeaderIcon: {
    type: String,
    default: 'cherry',
  },
});
const emits = defineEmits(['close',]);

const showModal = ref(false);
const draggable = ref(false);
const bottom = ref(0);
const isBottomSlider = ref(false);
const model = ref(null);
const container = ref(null);

const widthClasses = {
  sm: 'xs:max-w-sm',
  lg: 'xs:max-w-lg',
  xl: 'xs:max-w-xl',
  '2xl': 'xs:max-w-2xl',
  '3xl': 'xs:max-w-3xl',
  '4xl': 'xs:max-w-4xl',
  '5xl': 'xs:max-w-5xl',
  '6xl': 'xs:max-w-6xl',
  '7xl': 'xs:max-w-7xl',
  full: '',
  default: 'xs:max-w-md',
};
// if showing less than 30% mobile close the modal
const minViewableModal = 0.3;

const { y, } = useDraggable(container, {
  initialValue: { y: 0, },
});

const { lengthY, } = useSwipe(model);

const transitionNameComp = computed(() => props.transitionName || 'modal');
const modalClassList = computed(() => {
  let baseClasses = 'w-screen rounded-t-xl relative min-w-full cursor-auto max-h-[90dvh] overflow-y-auto xs:block xs:relative sm:rounded-xl scoll-container';
  if (props.mediaType !== 'tx') {
    baseClasses += ' p-4';
  }

  const backgroundColor = props.secondaryBackgroundColor ? 'bg-[#0F1115]' : 'bg-[#252831]';
  const media = props.mediaType === 'video' ? 'max-w-7xl pt-8' : '';
  const transition = transitionNameComp.value === 'modal-slide-up' ? '' : '';
  const width = widthClasses[props.modalWidthMode] || widthClasses.default;

  return [baseClasses, media, transition, backgroundColor, width,].join(' ').trim().replace(/\s+/g, ' ');
});

function activateDrag() {
  draggable.value = true;
}

function reset() {
  const modalHeight = model.value?.offsetHeight;

  if (draggable.value) {
    bottom.value = 0;
  }

  if (modalHeight && (y.value > modalHeight * minViewableModal)) {
    deactivateDrag();
  }
}

function deactivateDrag() {
  // stop deactivating drag event or draggable options if mobal is not in the mobile formate
  if (!isBottomSlider.value) {
    draggable.value = false;
    return;
  }

  const modalHeight = model.value?.offsetHeight;

  draggable.value = false;

  if (modalHeight && (y.value > modalHeight * minViewableModal || -lengthY.value > modalHeight * minViewableModal)) {
    bottom.value = -modalHeight;

    setTimeout(() => {
      showModal.value = false;
      emits('close');
    }, 600);
  } else {
    // reset modal to full view
    bottom.value = 0;
    draggable.value = false;
  }
}

function drag() {
  if (!draggable.value || !model.value || !container.value) {
    return;
  }

  const elementHeight = container.value?.offsetHeight;
  const other = model.value?.getBoundingClientRect();
  const modalPosition = elementHeight - other.bottom;

  // check if event is a touch event
  if (lengthY.value < 0) {
    bottom.value = modalPosition <= 0 ? lengthY.value : 0;
  } else if (y.value > 0) {
    bottom.value = modalPosition <= 0 ? -y.value : 0;
  }
}

function onResize() {
  const state = window.innerWidth < 431;
  setModel(state);
  isBottomSlider.value = state;
}

function closeModal() {
  if (!props.hideClose) {
    emits('close');
  }
}

function setModel(value) {
  if (!model.value) {
    return;
  }
  if (value) {
    model.value.style.left = '0px';
    model.value.style.bottom = '0px';
    model.value.style.position = 'fixed';
  } else {
    model.value.style.left = 'inherit';
    model.value.style.bottom = 'inherit';
    model.value.style.position = 'relative';
  }
}

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize());
  document.body.classList.add('overflow-hidden');
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize());
  document.body.classList.remove('overflow-hidden');
});
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: env(safe-area-inset-bottom);
}
.scoll-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
